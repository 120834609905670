
<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <b-col sm="6" lg="3">
          <b-card>
            <div>
              <h4 class="mb-1">{{ $t('message.promotionName') }}</h4>
              <h5 class="mb-1">
                &nbsp;
                <b-form-input v-model="promotion.name" type="text"></b-form-input>
              </h5>
              <h5>{{ $t('message.period') }}</h5>
              <date-picker
                type="datetime"
                v-model="promotion.time"
                lang="en"
                range
                format="YYYY-MM-dd"
              ></date-picker>
              <h5>{{ $t('message.priority') }}</h5>
              <b-form-select
                type="number"
                min="1"
                v-model="promotion.priority"
                :options="prioritySelect"
              ></b-form-select>
              <div class="table-responsive">
                <br />
                <br />
                <b-btn
                  variant="success"
                  v-on:click="showSlotsModal()"
                >{{ $t('message.deviceUsed') }}</b-btn>
              </div>
            </div>
          </b-card>
        </b-col>

        <template v-if="promotion.typeId === 1">
          <template v-for="(items, index, key) in promotion.config">
            <b-col sm="6" lg="2">
              <b-card no-body>
                <b-list-group flush>
                  <b-list-group-item>
                    <div class="float-right">
                      <b-btn variant="danger" v-on:click="deleteDate(index)">
                        <i class="fa fa-remove"></i>
                        &nbsp;{{ $t('message.delete') }}
                      </b-btn>
                    </div>
                  </b-list-group-item>
                  <div class="table-responsive">
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      {{ $t('message.days') }}
                      <b-form-select
                        v-model="items.day"
                        :options="$t('message.arrays.daySelector')"
                        style="width: 80%"
                      />
                    </b-list-group-item>
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;
                    <b-btn variant="success" v-on:click="editPrices(items.slots)">
                      <i class="fa fa-wrench"></i>
                      &nbsp;{{ $t('message.settingPrice') }}
                    </b-btn>
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;
                    <b-btn class="btn-coral" v-on:click="copyDay(items)">
                      <i class="fa fa-copy"></i>
                      &nbsp;{{ $t('message.copyDay') }}
                    </b-btn>
                    <br />
                    <br />

                    <template v-for="(item, index, key) in items.time">
                      <b-list-group-item>
                        <h5 class="mb-1">{{ $t('message.section') }} {{index + 1}}</h5>
                        <p class="mb-1">{{ $t('message.startTime') }}</p>
                        <b-form-input type="time" v-model="item.start"></b-form-input>
                        <p class="mb-1">{{ $t('message.endTime') }}</p>
                        <b-form-input type="time" v-model="item.end"></b-form-input>
                        <template v-if="items.time && index === items.time.length - 1">
                          <br />
                          <b-btn class="bg-primary float-right" v-on:click="addTime(items.time)">
                            <i class="fa fa-plus-square"></i>
                          </b-btn>
                          <b-btn class="btn-red float-right" v-on:click="deleteTime(items.time)">
                            <i class="fa fa-minus-square"></i>
                          </b-btn>
                          <br />
                          <br />
                        </template>
                      </b-list-group-item>
                    </template>
                    <template v-if="items.time && items.time.length <= 0">
                      <b-list-group-item>
                        <b-btn class="bg-primary float-right" v-on:click="addTime(items.time)">
                          <i class="fa fa-plus-square"></i>
                          &nbsp;{{ $t('message.addTime') }}
                        </b-btn>
                        <br />
                        <br />
                      </b-list-group-item>
                    </template>
                  </div>
                </b-list-group>
              </b-card>
            </b-col>
          </template>
        </template>

        <template v-else-if="promotion.typeId === 3">
          <b-col sm="6" lg="6">
            <b-card>
              <table class="table-l">
                <h4 class="mb-1">{{ $t('message.setting') }}</h4>
                <!-- {{promotion}} -->
                <tr>
                  <td>{{ $t('message.transactionType') }}</td>
                  <td>
                    <model-list-select
                      :list="type"
                      v-model="promotion.config.type"
                      option-value="type"
                      option-text="name"
                      isDisabled = true
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.count') }}</td>
                  <td>
                    <b-form-input type="number" min="1" v-model="promotion.config.count" disabled></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.reward') }}</td>
                  <td>
                    <model-list-select
                      :list="coupons"
                      v-model="promotion.config.code"
                      option-value="code"
                      option-text="name"
                      isDisabled = true
                    ></model-list-select>
                    <!-- <list-select
                    :list="coupons.coupons"
                    option-value="couponId"
                    option-text="name"
                    :selected-item="selectedCoupon"
                    @select="selectCoupon"
                    ></list-select>-->
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.valueIfFree') }}</td>
                  <td>
                    <b-form-input type="number" min="0" v-model="promotion.config.value" disabled></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.availiable') }} ({{ $t('message.day') }})</td>
                  <td>
                    <b-form-input
                      type="number"
                      min="1"
                      max="30"
                      v-model="promotion.config.expireDate"
                      disabled
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.type') }}</td>
                  <td>
                    <model-list-select
                      :list="stepType"
                      v-model="promotion.config.mode"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </template>

        <template v-else-if="promotion.typeId === 4">
          <b-col sm="6" lg="6">
            <b-card>
              <table class="table-l">
                <h4 class="mb-1">{{ $t('message.setting') }}</h4>
                <!-- {{promotion}} -->
                <tr>
                  <td>{{ $t('message.transactionType') }}</td>
                  <td>
                    <model-list-select
                      :list="type"
                      v-model="promotion.config.type"
                      option-value="type"
                      option-text="name"
                      isDisabled = true
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.type') }}</td>
                  <td>
                    <model-list-select
                      :list="stepType"
                      v-model="promotion.config.mode"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.setting') }}</td>
                  <td>
                    <b-btn
                      variant="primary"
                      @click="showSettingModal(promotion.config.details)"
                    >{{ $t('message.setting') }}</b-btn>
                  </td>
                </tr>
              </table>
            </b-card>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <b-card>
      <div align="right">
        <b-btn v-on:click="goBack" variant="danger" size="lg">{{ $t('message.back') }}</b-btn>&nbsp;
        <b-btn v-on:click="savePromotion" variant="success" size="lg">{{ $t('message.save') }}</b-btn>
      </div>
    </b-card>

    <b-modal ref="slotsModal" hide-header>
      <b-form-group>
        <template slot="label">
          <b>{{ $t('message.deviceId') }}</b>
          <br />
          <b-form-checkbox
            v-model="allSelected"
            @change="toggleAll"
          >{{ allSelected ? $t('message.cancelAll') : $t('message.selectAll') }}</b-form-checkbox>
        </template>
        <b-form-checkbox-group
          value-field="id"
          text-field="name"
          stacked
          v-model="promotion.slots"
          :options="device"
          class="ml-4"
        ></b-form-checkbox-group>
      </b-form-group>
      <p>
        {{ $t('message.deviceUsed') }}:
        <strong>{{promotion.slots}}</strong>
      </p>
    </b-modal>

    <b-modal ref="pricesModal" hide-header size="lg">
      {{pricesModalData}}
      <table class="table2">
        <template v-for="(items, index, key) in pricesModalData">
          <td>
            <tr>
              <strong>{{ $t('message.packet') }} {{index+1}}</strong>
            </tr>
            <tr>
              {{ $t('message.tmpOrTime') }} :
              <b-form-input type="number" min="0" v-model.number="items.value"></b-form-input>
            </tr>
            <tr>
              {{ $t('message.price') }} :
              <b-form-input type="number" min="0" v-model.number="items.price"></b-form-input>
            </tr>
          </td>
        </template>
      </table>
      <div slot="modal-footer" class="w-100">
        <b-btn
          variant="success"
          class="float-left"
          v-on:click="addPackage(pricesModalData)"
        >{{ $t('message.addPacket') }}</b-btn>&nbsp;
        <b-btn
          variant="success"
          v-on:click="deletePackage(pricesModalData)"
        >{{ $t('message.removePacket') }}</b-btn>
        <b-btn
          variant="primary"
          class="float-right"
          @click="closePricesModal()"
        >{{ $t('message.ok') }}</b-btn>
      </div>
    </b-modal>

    <b-modal ref="settingModal" hide-header size="lg">
      <table class="table2">
        <template v-for="(items, index, key) in settingModalData">
          <td>
            <tr>
              <strong>{{ $t('message.step') }} {{index+1}}</strong>
            </tr>
            <tr>
              {{ $t('message.startCount') }} :
              <b-form-input type="number" min="0" v-model="items.start"></b-form-input>
            </tr>
            <tr>
              {{ $t('message.endCount') }} :
              <b-form-input type="number" min="0" v-model="items.end"></b-form-input>
            </tr>
            <tr>
              {{ $t('message.discountPrice') }} :
              <b-form-input type="number" min="0" v-model.number="items.value"></b-form-input>
            </tr>
          </td>
        </template>
      </table>
      <div slot="modal-footer" class="w-100">
        <b-btn
          variant="success"
          class="float-left"
          v-on:click="addPackage(settingModalData)"
        >{{ $t('message.addPacket') }}</b-btn>&nbsp;
        <b-btn
          variant="success"
          v-on:click="deletePackage(settingModalData)"
        >{{ $t('message.removePacket') }}</b-btn>
        <b-btn
          variant="primary"
          class="float-right"
          v-on:click="closeSettingModal()"
        >{{ $t('message.ok') }}</b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import DatePicker from "../../custom_modules/vue2-datepicker";
import moment from "moment";
import webServices from "../../script";
import {
  ListSelect,
  ModelListSelect
} from "../../custom_modules/search-select";
export default {
  name: "promotions",
  components: {
    DatePicker,
    ListSelect,
    ModelListSelect
  },
  data() {
    return {
      searchDevice: {
        machineId: this.$route.params.machineId
      },
      pricesModalData: [],
      allSelected: false,
      indeterminate: false,
      daySelector: [
        { value: 0, text: "อาทิตย์" },
        { value: 1, text: "จันทร์" },
        { value: 2, text: "อังคาร" },
        { value: 3, text: "พุธ" },
        { value: 4, text: "พฤหัส" },
        { value: 5, text: "ศุกร์" },
        { value: 6, text: "เสาร์" }
      ],
      prioritySelect: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" }
      ],
      slots: [],
      searchData: {
        machineId: this.$route.params.machineId,
        promotionId: this.$route.params.promotionId
      },
      promotion: {},
      promotion: {},
      device: [],
      selectedDevice: "",
      coupons: [],
      type: [
        { name: "ซักผ้า", type: "washer" },
        { name: "อบผ้า", type: "dryer" },
        { name: "ซักและอบ", type: "wnd" },
        { name: "เครื่องขายน้ำยา", type: "vending" }
      ],
      stepType: [
        { name: "ช่วงเวลา", type: "period" },
        { name: "ตัดรายเดือน", type: "monthly" }
      ],
      settingModalData: []
    };
  },
  methods: {
    showSettingModal(data) {
      this.settingModalData = data;
      this.$refs.settingModal.show();
    },
    addPackage(data) {
      let lastItem = Object.assign({}, data[data.length - 1]);
      data.push(lastItem);
    },
    deletePackage(data) {
      data.pop();
    },
    closeSettingModal() {
      this.$refs.settingModal.hide();
    },
    getPromotion() {
      this.$Progress.start();
      webServices
        .getPromotion(this.searchData)
        .then(res => {
          this.$Progress.finish();
          this.promotion = res.data;
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @get Promotion");
          console.log(err);
        });
    },
    toggleAll(checked) {
      if (checked) {
        this.device.forEach(value => {
          this.promotion.slots.push(value.id);
        });
      } else {
        this.promotion.slots = [];
      }
    },
    showSlotsModal() {
      this.$refs.slotsModal.show();
    },
    editPrices(data) {
      this.pricesModalData = data;
      this.$refs.pricesModal.show();
      //  console.log(this.pricesModalData)
    },
    closePricesModal() {
      this.$refs.pricesModal.hide();
    },
    goBack() {
      this.$router.push("/promotion");
    },
    addTime(data) {
      let lastItem = Object.assign({}, data[data.length - 1]);
      data.push(lastItem);
    },
    deleteTime(data) {
      data.pop();
    },
    copyDay(data) {
      let lastItem = JSON.parse(JSON.stringify(data));
      lastItem.day += 1;
      this.promotion.config.push(lastItem);
    },
    deleteDate(index) {
      this.promotion.config.splice(index, 1);
    },
    getCoupons() {
      webServices.getCoupons().then(res => {
        this.coupons = res.data.coupons;
      });
    },
    //kan
    getDevice() {
      webServices
        .getDevice(this.searchDevice.machineId)
        .then(res => {
          if (res.data.length >= 0) {
            this.device = res.data;
            console.log(this.device);
          } else {
            console.log(res.data);
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @savePromotion");
          console.log(err);
        });
    },
    savePromotion() {
      var timeEnd = moment(this.promotion.time[1]).format("HH:mm:ss");
      if (timeEnd === "00:00:00" || timeEnd === "12:00:00 AM") {
        // console.log('changetime')
        this.promotion.time[1].setHours(23, 59, 59, 999);
      }
      webServices
        .editPromotion(this.promotion)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.$router.push("/promotion/all");
          } else {
            console.log(res.data);
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @savePromotion");
          console.log(err);
        });
    }
  },
  mounted() {
    //  this.getSlots()
    this.getPromotion();
    this.getDevice();
    this.getCoupons();
  }
};
</script>
